import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { AnimatePresence, motion } from 'framer-motion'




function SubtitlesOnlyOverlay(props) {


  const { subtitle } = props
  const [overlay, setOverlay] = useState(null)




  useEffect(() => {
    setOverlay(subtitle.snippet)
  }, [subtitle])




  return (
    <AnimatePresence>
      {(overlay) &&
        <motion.div
          key={overlay.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed top-0 left-0 w-full h-screen px-10"
        >
          <p className="absolute bottom-4 left-1/2 w-full h-[135px] max-w-6xl px-3 py-1 pb-5 text-5xl text-white-600 font-regular leading-tight bg-black text-gray-100 transform -translate-x-1/2 rounded-md">
            {overlay.displayText}
          </p>
        </motion.div>
      }
    </AnimatePresence>
  )
}





const mapStateToProps = (state) => {
  const subtitles = state.firestore.ordered.overlays
  const subtitle = (subtitles)? subtitles[0] : null

  return { subtitle: { ...subtitle } }
}

export default compose(connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.match.params.id) return []

    return [
      { 
        collection: 'projects',
        doc: props.match.params.id,
        subcollections: [{ collection: 'overlays', where: [['type', '==', 'subtitle']] }],
        storeAs: 'overlays'
      }
    ]
  })
)(SubtitlesOnlyOverlay)
