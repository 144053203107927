import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { useIntl } from 'react-intl';






function PresenterNotifications(props) {
  


  const {

    notes,
    project,
    darkMode,

  } = props;

  const intl = useIntl();

  const sizes = ['lg', 'xl', '2xl', '3xl', '4xl']
  const sizesX2 = ['xl', '2xl', '3xl', '4xl', '5xl']
  var firstime = true;



  const [textSize, setTextSize] = useState(0)
  const [settingDarkMode, setSettingDarkMode] = useState(false);

  const [numberOfMssges, setNumberOfMssges] = useState(0);
  const [hour, setHour] = useState(new Date());








  useEffect(() => {
    document.title = `${intl?.formatMessage({ id: "presenter_notifications_panel" })} - SSTRM`

    if (firstime) {
      firstime = false;
      setInterval(() => setHour(new Date()), 500);
    }
  }, []);


  useEffect(() => {
    var num = 0;


    if (project?.items?.length) {
      for (var element of project?.items){
        if (element?.type !== 'chyron' && element?.isReady) { num++; }
      };
    }

    setNumberOfMssges(num);
  }, [project]);


  useEffect(() => {
    if (darkMode) { document.documentElement.classList.toggle("dark"); }

    if (settingDarkMode) {
      document.documentElement.classList.toggle("dark");
    } else {
      document.documentElement.classList.toggle("dark");
    }

  }, [settingDarkMode, darkMode]);


  useEffect(() => {
    setSettingDarkMode(darkMode);
  }, [darkMode]);

  




  function checkNotas() {
    const regex = notes?.replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('<h2 class="text-red-500">', '').replaceAll('<h2 class="text-green-500">', '').replaceAll('</h2>', '').replaceAll('<i>', '').replaceAll('</i>', '').replaceAll('<u>', '').replaceAll('</u>', '').replaceAll('<strong>', '').replaceAll('</strong>', '');
    return (notes && regex !== 'undefinedundefined' && regex !== '')? true : false
  }







  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-white">
      
      {/* Top panel */}
      <div className={`px-6 h-[50px] w-full flex items-center justify-end space-x-8 bg-transparent select-none text-${sizes[textSize]}`}>
        <div className="w-[140px] flex items-center justify-around">
          <div className="w-[90px] flex items-center justify-around">
            <button onClick={() => setTextSize(textSize-1)} className="font-bold text-gray-500 cursor-pointer" disabled={textSize === sizes.length-sizes.length}>
              <p>A-</p>
            </button>

            <button onClick={() => setTextSize(textSize+1)} className="font-bold text-gray-500 cursor-pointer" disabled={textSize === sizes.length-1}>
              <p>A+</p>
            </button>
          </div>

          <button onClick={() => setSettingDarkMode(!settingDarkMode)}>
            <p>{(settingDarkMode)? "🌚" : "🌞"}</p>
          </button>
        </div>
      </div>


      <div className={`p-6 pt-12 w-full h-full flex flex-wrap fixed pointer-events-none text-${sizesX2[textSize]}`}>
        {/* Time */}
        <div 
          className={`w-full relative border-4 rounded p-2 text-center mb-2 font-semibold 
            ${(!settingDarkMode)? 'border-gray-900' : 'border-gray-400'}
          `}
        >
          <div className="w-full h-full flex items-center">
            <div className="font-semibold w-full">
              {hour.getHours()+':'+(hour.getMinutes() < 10? '0'+hour.getMinutes() : hour.getMinutes())+':'+(hour.getSeconds() < 10? '0'+hour.getSeconds() : hour.getSeconds())}
            </div>
          </div>
        </div>



        <div className="w-full flex space-x-2">
          {/* Notes */}
          <div
            className={`w-full border-4 rounded p-2 text-center font-semibold flex items-center
              ${(!settingDarkMode)? 'border-gray-900' : 'border-gray-400'}
              ${(checkNotas())? '' : 'opacity-30' }
            `}
          >
            <h1 className="w-full font-semibold">{(checkNotas())? "CON NOTAS" : "SIN NOTAS"}</h1>
          </div>

          {/* Marks */}
          <div
            className={`w-full relative border-4 rounded p-2 text-center font-semibold 
              ${(!settingDarkMode)? 'border-gray-900' : 'border-gray-400'}
              ${(numberOfMssges)? '' : 'flex items-center opacity-30' }
            `}
          >
            <div className="absolute w-full pr-[15px]">
              <h1 className="w-full font-semibold">{(numberOfMssges)? "CON MENSAJES" : "SIN MENSAJES"}</h1>
              {(numberOfMssges)? <hr className={`mt-1 border rounded-full ${(!settingDarkMode)? 'border-gray-900 bg-gray-900' : 'border-gray-400 bg-gray-300'}`}></hr> : null}
            </div>

            <div className="w-full h-full flex items-center">
              {(numberOfMssges)? <div className="font-semibold w-full">{numberOfMssges}</div> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}






export default compose(

  firestoreConnect((props) => {
    const projectId = (props?.match?.params?.id)? props?.match?.params?.id : (props?.projectId)? props?.projectId : null;
    if (!projectId) return [];

    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [ { collection: "items" } ],
        storeAs: "items",
      },
    ];
  }),

  connect((state, ownProps) => {
    const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
    const projects = state.firestore.data?.projects ?? null
    const project = projects ? projects[projectId] : null
    const { items } = state.firestore.ordered;

    return {
      notes: project?.notes?.html,
      project: {
        ...project,
        items: items?.filter((item) => item?.isReady && !item?.isEmitted && (item?.type?.toLowerCase()?.includes('message')) ),
      },
    }

  },
  
  (dispatch, ownProps) => {
    return {}
  })

)(PresenterNotifications);





