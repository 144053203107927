import { LANGUAGES } from '../languages'

const ca = {
  [LANGUAGES.ca]: {

    /* Others */
    "hello": "Bon dia, com va tot?",
    "catala": "Català",
    'castella': "Castellà",
    'titol_configuracio': "Configuració",

    /* Titles */
    'room': "Sala",
    'rooms': "Salas",
    'global_panel': "Panell global",
    'text_message': "Missatge de text",
    'text_messages': "Missatges de text",
    'audio_message': "Missatge d'audio",
    'audio_messages': "Missatges d'audio",
    'card': "Targeta",
    'cards': "Targetes",
    'subtitler': "Subtítulador",
    'subtitles': "Subtítols",
    'poll': "Enquesta",
    'polls': "Enquestas",
    'obs_scene_links': "Enllaços de les escenes d'OBS",
    'style_configurator': "Editor d'estils",
    'emiter_panel': "Panell d'emissió",
    'subtitler_panel': "Panell de subtitulació",
    'text_message_panel': "Panell d'inserció de missatges de text",
    'audio_message_panel': "Panell d'inserció de missatges d'àudio",
    'presenter_panel': "Panell del presentador",
    'presenter_notifications_panel': "Panell de notificacions del presentador",
    'widgets_and_panels': "Panels / Widgets de la sala",
    'text_messages_moderator': "Moderador de missatges de text",
    'audio_messages_moderator': "Moderador de missatges d'àudio",
    'subtitles_plus_video': "Subtítulos + Vídeo",

    /* Labels */
    'room_name': "Nom de la sala",
    'write_room_name': "Escriu un nom per aquesta sala",
    'name': "Nom",
    'write_the_name': "Escriu el nom",
    'busines_name': "Nom de l'empresa",
    'write_the_busines_name': "Escriu el nom de l'empresa",
    'font_size': "Tamany del text",
    'small_text': "Text petit",
    'normal_text': "Text normal",
    'big_text': "Text gran",
    'text_of_the_message': "Text del missatge",
    'write_text_of_the_message': "Escriu el text del missatge",
    'created_at': "Creat a les",
    'updated_at': "Actualitzat a les",
    'category': "Categoria",
    'title': "Títol",
    'write_the_title': "Escriu un títol",
    'intern_title': "Títol intern",
    'write_the_intern_title': "Escriu un títol intern",
    'subtitle': "Subtítol",
    'inferior_texts': "Texts inferiors",
    'write_youtube_id': "Escriu l'ID de Youtube",
    'subtitles_public_url': "URL pública dels subtítols",
    'write_url': "Escriu l'URL",
    'option': "Opció",
    'write_option': "Escriu l'opció",
    'search_bar': "Barra de cerca",
    'vote': "Vot",
    'votes': "Vots",
    'presenters': "Presentadors",
    'emisor': "Emisors",
    'personalization': "Personalicació",
    'live_chats': "Chats en directe YouTube / Twitch",
    'elements_list': "Llistat d'elements",
    'presenter_notes': "Notes del presentador",
    'element_creator': "Creador d'lements",
    'awaiting': "A l'espera",
    'welcome_to_sstrm': "Benvingut a sstrm",
    'login_with_google': "Iniciar sessió amb Google",
    'loading': "Carregant...",
    'project_not_found': "Projecte no trobat",

    /* Buttons */
    'logout': "Tancar sessió",
    'configuration': "Configuració",
    'dark_mode': "Mode fosc",
    'save': "Desar",
    'saving': "Desant...",
    'new_room': "Nova sala",
    'room_list': "Llistat de salas",
    'add_new_room': "Crear una nova sala",
    'go_back': "Tornar",
    'cancel': "Cancel·lar",
    'show_portrait': "Mostrar retrato",
    'image': "Imatge",
    'remove_image': "Esborrar imatge",
    'upload_image': "Pujar imatge",
    'show_preview': "Vista prèvia",
    'remove_audio': "Esborrar àudio",
    'upload_audio': "Pujar àudio",
    'emited_at': "Emès a les",
    'not_emiting': "Cap element en emisió",
    'emiting_elements': "Emetin elements",
    'stop_emiting_all': "Detenir elements en transmissió",
    'stop_emit_subtitles': "Detenir emisió dels subtítles",
    'emit_subtitles': "Emetre subtítles",
    'remove_option': "Eliminar opció",
    'remove': "Eliminar",
    'add_option': "Afegir opció",
    'reset_votes': "Reiniciar vots",
    'poll_link': "Enllaç enquesta",
    'show_qr': "Mostrar QR",
    'hide_qr': "Amagar QR",
    'open_poll': "Obrir enquesta",
    'close_poll': "Tancar enquesta",
    'see_votes': "Veure vots",
    'link_account_to_start': "Enllaça el compte per començar",
    'select_active_live': "Selecciona una emissió activa",
    'refresh_active_live': "Refrescar emissions actives",
    'live_chat': "Xat en directe",
    'obs_links': "Enllaços d'escena (OBS)",
    'new_group': "Nou grup",
    'settings': "Configuració",
    'language': "Idioma",
    'emiting': "Emitint",
    'confirmed': "Confirmat",
    'confirm': "Confirmar",
    'edit': "Editar",
    'exit': "Sortir",
    'go_back_to_start': "Tornar a l'inicial",
    'new_elements': "Nous elements",

    /* Long */
    'search_text': "Cerca els teus projectes pel seu nom.",
    'search_voters': "Cerca les dades dels votants",
    'sure_delete_room': "Segur que vol eliminar aquesta sala, junt amb tota la informació que conté?",
    'sure_delete_element': "Segur que vol eliminar aquest element de forma definitiva?",
    'sure_reset_votes': "Segur que vol eliminar els vots d'aquesta enquesta de forma definitiva?",
    'sure_edit_poll': "Segur que vol editar aquesta enquesta? La seva edició pot significar una perdua dels vots introduits.",
    'no_data_found': "No s'han trobat dades",
    'no_active_lives': "No s'han trobat emissions actives",
    'global_note_removed': "Aquesta nota ha sigut eliminada pel presentador, edita-la o fes-ne una de nova perquè la visualitzi.",
    'could_not_find_projext': "No hem pogut trobar cap projecte amb aquesta ID, assegureu-vos que la sala és correcta o contacteu amb el desenvolupador",

  }
}

export default ca