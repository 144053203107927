import { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { LayoutContext } from '../contexts/Layout'

import { connect } from 'react-redux'
import { createProject } from './../actions/projectActions'
import { authenticate } from './../actions/youtubeActions'

import translate from "../i18n/translate";
import { useIntl } from 'react-intl'

import TextField from "../components/Inputs/TextField";









function Room({
  
  createProject,
  auth,
  new_project_id,
  history,

}) {



  const { setSuccessAlertMsg, setErrorAlertMsg, } = useContext(LayoutContext)
  const intl = useIntl();


  const [name, setName] = useState(null)  
  const [loadingSave, setLoadingSave] = useState(false)  


  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h426q14.22 0 27.11 5Q682-806 693-795l102 102q11 11 16 23.89t5 27.11v426q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm264-108q45 0 76.5-31.5T588-360q0-45-31.5-76.5T480-468q-45 0-76.5 31.5T372-360q0 45 31.5 76.5T480-252ZM300-552h264q15.3 0 25.65-10.32Q600-572.65 600-587.91v-71.83q0-15.26-10.35-25.76Q579.3-696 564-696H300q-15.3 0-25.65 10.32Q264-675.35 264-660.09v71.83q0 15.26 10.35 25.76Q284.7-552 300-552Z"/></svg>
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m438-480 164 164q11 11 11 25.5T602-265q-11 11-25.5 11t-25.84-11.34L361-455q-5-5.4-7.5-11.7-2.5-6.3-2.5-13.5t2.5-13.5Q356-500 361-505l189.66-189.66Q562-706 576.5-706t25.5 11q11 11 11 25.5T602-644L438-480Z"/></svg>
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  useEffect(() => {
    document.title = `${intl?.formatMessage({ id: "new_room" })} - SSTRM`
  }, []);




  async function handleSubmit(event) {
    setLoadingSave(true)

    if (name) {
      await createProject({ 
        title: name,
        publicURL: name.toLowerCase().split(/[_\s]/).join("-").replaceAll('/', ''),
      })
    }

    setTimeout(() => {
      history.push(`/rooms`)
      setSuccessAlertMsg('Sala guardada correctamente.')

      setLoadingSave(false)
    }, 500 )
  }







  if(!auth.uid) return <Redirect to="/signin" />

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="w-full max-w-[600px] space-y-3 p-4 md:p-8">
        <h1 className="text-3xl font-black">{translate("new_room")}</h1>

            
        <div className='py-1'>
          <TextField
            label={intl?.formatMessage({ id: "room_name" })}
            placeholder={intl?.formatMessage({ id: "write_room_name" })}
            className='w-full bg-gray-100 dark:bg-gray-700'
            disabled={loadingSave}

            value={(name)? name : ''}
            onChange={(event) => setName((event.target.value)? event.target.value?.slice(0, 30) : null)}
            onKeyUp={(event) => (event.key === 'Enter' && name)? handleSubmit() : null }
          />
        </div>


        <div className='flex items-center justify-between space-x-4'>
          <button
            className="pl-1 pr-3 py-1 flex items-center space-x-1 text-blue-500 hover:bg-gray-100 dark:hover:bg-gray-700 active:scale-95 duration-200 hover:shadow-sm rounded-md"
            onClick={() => history.push(`/rooms`)}
            disabled={loadingSave}
          >
            {leftArrowIcon}
            <p>{translate("go_back")}</p>
          </button>

          <button
            className="pl-2 pr-3 py-1 flex items-center space-x-1 text-white bg-blue-500 hover:bg-blue-700 active:scale-95 duration-200 shadow-md rounded-md"
            onClick={() => handleSubmit()}
            disabled={loadingSave || !name}
          >
            {(loadingSave)? loadingIcon : saveIcon}
            <p>{translate("add_new_room")}</p>
          </button>
        </div>
      </div>
    </div>
  )
}






const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    new_project_id: state.project.new_project_id,
    isAuthenticated: state.project.isAuthenticated,
    broadcasts: state.project.broadcasts
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createProject: (project) => dispatch(createProject(project)),
    authenticate: () => dispatch(authenticate()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Room)