import { useState } from "react";
import ReactAudioPlayer from "react-audio-player";

import ViewButtons from "../../ViewButtons";

import translate from "../../../../i18n/translate";




function AudioMessagePanelView({

  overlayItem,
  role,
  time,

  showPreview,
  setShowPreview,
  projectStyles,

  isEmiting,
  stopEmitingFunc,
  startEmitingFunc,
  setIsEmitted,

  editItemFunc,
  toggleItemIsReady,

}) {



  const [correctPicture, setCorrectPicture] = useState((overlayItem?.profileImageUrl)? true : false);
  const emittedTime = overlayItem?.emittedAt?.toDate() ?? null;

  const userIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M237-285q54-38 115.5-56.5T480-360q66 0 127.5 18.5T723-285q35-41 52-91t17-104q0-129.675-91.23-220.838Q609.541-792 479.77-792 350-792 259-700.838 168-609.675 168-480q0 54 17 104t52 91Zm243-123q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm.276 312Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q52 0 100-16.5t90-48.5q-43-27-91-41t-99-14q-51 0-99.5 13.5T290-233q42 32 90 48.5T480-168Zm0-312q30 0 51-21t21-51q0-30-21-51t-51-21q-30 0-51 21t-21 51q0 30 21 51t51 21Zm0-72Zm0 319Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 316-265q-11 11-25 10.5T266-266q-11-11-11-25.5t11-25.5l163-163-164-164q-11-11-10.5-25.5T266-695q11-11 25.5-11t25.5 11l163 164 164-164q11-11 25.5-11t25.5 11q11 11 11 25.5T695-644L531-480l164 164q11 11 11 25t-11 25q-11 11-25.5 11T644-266L480-429Z"/></svg>

  const displayTimeHour = (time) => new Intl.DateTimeFormat("es", { hour: "numeric", minute: "numeric" }).format(time);



  return <div className="flex-1 flex flex-col">
    <div className="flex items-start space-x-2">
      {(role !== "presenter") && (
        <div className="w-24 h-24 min-w-[96px] flex items-center justify-center border border-gray-300 dark:border-gray-600 rounded-md overflow-hidden">
          {(overlayItem?.showInitials) && 
            ((overlayItem?.profileImageUrl && correctPicture)?
              <div className="w-24 h-24 min-w-[96px]">
                <img 
                  src={overlayItem?.profileImageUrl}
                  className={`w-24 h-24 min-w-[96px] ${(role === "presenter")? "filter grayscale opacity-60" : ""}` }
                  onError={() => setCorrectPicture(false)}
                />
              </div>
            :
              <div className="w-full h-full bg-white grid place-items-center font-regular text-2xl pointer-events-none">
                {<div className='opacity-10 scale-[4]'>{userIcon}</div>}
              </div>
            )
          }
        </div>
      )}
      

      <div className={`w-full ${(role !== "presenter")? 'pb-2' : ''}`}>
        <div className="pb-1 w-full flex items-start justify-between space-x-2">
          <div className="pt-1 flex items-center space-x-2">
            <p className="font-semibold truncate">{overlayItem?.displayName} {(overlayItem?.businessName) && <span className="font-normal">de {overlayItem?.businessName}</span>}</p>
            
            {(role !== "presenter") && 
              <p className="hidden xl:block w-min py-1 px-2 bg-gray-400 bg-opacity-30 opacity-50 dark:bg-gray-600 rounded-md text-xs whitespace-nowrap">
                {(overlayItem?.fontSize === '50')? translate("small_text") : (overlayItem?.fontSize === '100')? translate("normal_text") : translate("big_text")}
              </p>
            }
          </div>

          {(role !== "presenter") && 
            <div className="relative w-[140px] hidden lg:flex flex-col items-end">
              <p className="absolute top-0 right-[2px] whitespace-nowrap text-xs opacity-40">{(overlayItem?.lastUpdate)? translate("updated_at") : translate("created_at")} {displayTimeHour(time)}h</p>
              {(overlayItem?.isEmitted) && 
                <div className="absolute top-[14px] right-[2px] flex space-x-1 items-center whitespace-nowrap text-xs opacity-40">
                  <button onClick={() => setIsEmitted(false, overlayItem?.id)} className="hover:text-black duration-200">{cancelIcon}</button>
                  <p>{translate("emited_at")} {displayTimeHour(emittedTime)}</p>
                </div>
              }
            </div>
          }
        </div>

        <div className='pt-1'>
          <div className='w-full rounded-md bg-gray-200 opacity-50 hover:bg-opacity-60 hover:opacity-100 duration-200'>
            <ReactAudioPlayer
              className="audioMessage w-full"
              src={overlayItem?.audioMessageUrl}
              controls
            />
          </div>
        </div>
      </div>
    </div>



    {/* Buttons */}
    {(role !== "presenter") &&
      <ViewButtons
        overlayItem={overlayItem}
        role={role}
        type={translate("audio_message")}

        showPreview={showPreview}
        setShowPreview={setShowPreview}
      
        isEmiting={isEmiting}
        stopEmitingFunc={stopEmitingFunc}
        startEmitingFunc={startEmitingFunc}
      
        editItemFunc={editItemFunc}
        toggleItemIsReady={toggleItemIsReady}
      />
    }
  </div>
}

export default AudioMessagePanelView;
