import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyB-ANwVofZJJxEgcUcvPjeu7MxD1rlbfi8",
  authDomain: "stream-overlays-297e9.firebaseapp.com",
  databaseURL: "https://stream-overlays-297e9-default-rtdb.firebaseio.com",
  projectId: "stream-overlays-297e9",
  storageBucket: "stream-overlays-297e9.firebasestorage.app",
  messagingSenderId: "947072726082",
  appId: "1:947072726082:web:cf5b5409fc9299b86629db"
};

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
