import { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { useIntl } from "react-intl";

import translate from "../i18n/translate";

import { broadcastList, broadcastInfo } from "./../actions/youtubeActions";
import { saveItem, deleteItem, setCategories, setIsEmitted } from "../actions/projectActions";
import { addOverlay, removeOverlay } from "../actions/overlayActions";

import GlobalNotes from '../components/GlobalNotes'
import OverlayCreate from "../components/OverlayCreate";
import LinkButton from "../components/Inputs/LinkButton";

import MessageCreate from "../components/Overlays/Message/MessageCreate";
import AudioMessageCreate from "../components/Overlays/AudioMessage/AudioMessageCreate";
import ChyronCreate from "../components/Overlays/Chyron/ChyronCreate";
import CardCreate from "../components/Overlays/Card/CardCreate";
import SubtitlesCreate from "../components/Overlays/Subtitles/SubtitlesCreate";
import PollCreate from "../components/Overlays/Poll/PollCreate";

import LiveChatTwitch from "../components/Overlays/WithoutOverlay/LiveChatTwitch";
import LiveChatYouTube from "../components/Overlays/WithoutOverlay/LiveChatYouTube";
import ModalRoomSettings from "../components/ModalRoomSettings";
import MenuUserProfile from "../components/MenuUserProfile";
import { Link } from "react-router-dom/cjs/react-router-dom.min";







function RoomPanel(props) {


  const {

    project,
    match,
    auth,
    isAuthenticated,
    broadcastInfo,
    removeOverlay,
    setIsEmitted,

  } = props

  const hasWidget = (widget) => project.widgets?.includes(widget) ?? null;
  const intl = useIntl();



  const [overlay, setOverlay] = useState(null);
  const [showRoomSettingsModal, setShowRoomSettingsModal] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [widgetBoxHasScroll, setWidgetBoxHasScroll] = useState(false);
  const [showingPreview, setShowingPreview] = useState(false);

  const [baseLoading, setBaseLoading] = useState(true);

  /* Icon vars */
  const optionsIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M440-96q-17 0-29-10t-15-26l-15-78q-23-9-44.5-21T296-259l-75 26q-15 5-30.5-.5T167-253l-40-70q-8-14-5-29.5t15-26.5l59-52q-2-12-3-24t-1-25q0-13 1-25t3-24l-59-52q-12-11-15-26.5t5-29.5l40-70q8-14 23.5-19.5t30.5-.5l75 26q19-16 40.5-28t44.5-21l15-78q3-16 15-26t29-10h80q17 0 29 10t15 26l15 78q23 9 44.5 21t40.5 28l75-26q15-5 30.5.5T793-707l40 70q8 14 5 29.5T823-581l-59 52q2 12 3 24t1 25q0 13-1 25t-3 24l59 52q12 11 15 26.5t-5 29.5l-40 70q-8 14-23.5 19.5t-30.5.5l-75-26q-19 16-40.5 28T579-210l-15 78q-3 16-15 26t-29 10h-80Zm40-240q60 0 102-42t42-102q0-60-42-102t-102-42q-60 0-102 42t-42 102q0 60 42 102t102 42Z"/></svg>
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m438-480 164 164q11 11 11 25.5T602-265q-11 11-25.5 11t-25.84-11.34L361-455q-5-5.4-7.5-11.7-2.5-6.3-2.5-13.5t2.5-13.5Q356-500 361-505l189.66-189.66Q562-706 576.5-706t25.5 11q11 11 11 25.5T602-644L438-480Z"/></svg>
  const sadFaceIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480.16-503q54.84 0 94.34 39.5T622-368q2.38 14.22 13.06 23.11Q645.75-336 660-336q15 0 25.5-11t8.5-25q-10-85-69.5-144T480-575q-85 0-144.5 59T266-372q-2 15.43 8.5 25.71Q285-336 299.57-336t25.5-9Q336-354 338-368q8-56 47.66-95.5 39.65-39.5 94.5-39.5ZM420-646q12-9.45 11.5-24.23Q431-685 420-695.5t-26.5-10Q378-705 365-696q-11 8-22.45 14.58Q331.09-674.85 318-670q-14.22 6.2-23.11 18.6Q286-639 288-624q2 15 14.5 24t26.5 4q24.77-8.05 47.88-20.52Q400-629 420-646Zm120 0q19.71 17.29 42.86 29.65Q606-604 631-596q14 5 26.5-4t14.5-24q2-15-7.14-27.4-9.15-12.4-22.86-18.6-12.67-4.67-24.33-11.33Q606-688 595-696q-13-9-28.5-9.5t-26.5 10q-11 10.5-11.5 25.27Q528-655.45 540-646ZM480.28-96Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30ZM480-480Zm0 312q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Z"/></svg>

  const emitingOffIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"/></svg>
  const emitingOnIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"/></svg>



  



  useEffect(() => {
    window?.addEventListener('scroll', windowScrollListenerFunc, { passive: true });
    document?.getElementById('widget-box')?.addEventListener('scroll', widgetBoxScrollListenerFunc, { passive: true });

    return () => {
      window?.removeEventListener('scroll', windowScrollListenerFunc);
      document.getElementById('widget-box')?.removeEventListener('scroll', widgetBoxScrollListenerFunc);
    };
  }, []);


  useEffect(() => {
    document.title = `${intl?.formatMessage({ id: "global_panel" })} / ${project?.title} - SSTRM`
  }, [project]);


  useEffect(() => {
    if (baseLoading) {
      setTimeout(() => {
        setBaseLoading(false)
      }, 500 )
    }
  }, [baseLoading]);


  useEffect(() => {
    if (isAuthenticated) {
      broadcastInfo(project.broadcastId);
    }
  }, [isAuthenticated]);











  function widgetBoxScrollListenerFunc() {
    const widgetBoxEl = document.getElementById('widget-box');

    if (widgetBoxEl) {
      const hasHorizontalScrollbar = widgetBoxEl.scrollWidth > widgetBoxEl.clientWidth;
      const hasVerticalScrollbar = widgetBoxEl.scrollHeight > widgetBoxEl.clientHeight;
      setWidgetBoxHasScroll(hasVerticalScrollbar)
    }
  }


  function windowScrollListenerFunc() {
    setScrollPosition(window.pageYOffset)
  }


  function submitOverlay(messageId) {
    if (project?.overlay) {
      removeOverlay();
      setOverlay(null);

      if (messageId) setIsEmitted(true, messageId);
      if (!messageId && project?.overlays?.length) project?.overlays?.map((el) => setIsEmitted(true, el));
    } else {
      addOverlay(overlay);
    }
  };






  if (!auth.uid) return <Redirect to="/signin" />;

  return (
    <div className="w-full min-w-[455px] max-w-[1500px] mx-auto px-4 lg:px-8 py-76 lg:py-[112px]">
      <div className={`sticky top-0 left-0 flex items-end justify-between space-x-2 py-3 bg-white dark:bg-gray-800 border-b ${(showingPreview)? 'z-10' : 'z-30'} ${(scrollPosition > 100)? 'border-gray-300' : 'border-transparent'}`}>
        <div>
          <div className="flex items-center">
            <Link to="/rooms" className="w-auto flex items-center text-gray-400 hover:text-blue-700 active:scale-90 duration-200 text-sm">
              {leftArrowIcon}
              <p>{translate("go_back_to_start")}</p>
            </Link>
          </div>

          <div className="flex items-end space-x-3 font-black">
            <h1 className="text-3xl lg:text-4xl whitespace-nowrap">{translate("global_panel")}</h1>
            <p className="truncate text-xl opacity-40 hidden sm:block"> / {(baseLoading)? translate("loading") : (project?.title)? project?.title : translate("project_not_found")}</p>
          </div>
        </div>
    

        {(baseLoading)?
          <div className="flex justify-between items-end space-x-2 animate-pulse">
            <div className="pr-[36px] lg:pr-[137px] pb-[34px] bg-gray-200 rounded-md"></div>
            <div className="pr-[57px] pb-[34px] bg-gray-200 rounded-md"></div>
            <div className="pr-[52px] md:pr-[251px] pb-[48px] bg-gray-200 rounded-md"></div>
          </div>
        : (project?.id) && 
          <div className="flex justify-between items-end space-x-2">
            <button onClick={() => setShowRoomSettingsModal(!showRoomSettingsModal)} className="p-[6px] pr-2 flex items-center space-x-2 text-sm text-gray-500  border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-900 active:scale-95 transition-all duration-200 shadow-sm hover:drop-shadow-md rounded-md">
              {optionsIcon}
              <p className="hidden lg:block">{translate("settings")}</p>
            </button>

            <ModalRoomSettings
              projectId={project.id}
              open={showRoomSettingsModal}
              setOpen={setShowRoomSettingsModal}
            />
            
            <MenuUserProfile
              arrow={true} 
            />


            <button
              className={`px-4 py-3 flex items-center space-x-2 justify-center font-semibold text-sm hover:bg-red-700 hover:shadow-lg text-white rounded-md duration-200
                ${(project?.overlay)? 'bg-red-600' : 'bg-red-900 bg-opacity-70'}
              `}
              onClick={() => submitOverlay()}
              disabled={!project?.overlay && !overlay}
            >
              {(project?.overlay)? emitingOnIcon : emitingOffIcon}
              <p className="hidden md:block">{(project?.overlay)? translate("stop_emiting_all") : translate("not_emiting")}</p>
            </button>
          </div>
        }
      </div>



      {(baseLoading)?
        <div className="w-full h-full flex items-start justify-start flex-wrap lg:flex-nowrap space-y-12 lg:space-y-0 lg:space-x-4 min-w-0 animate-pulse">
          <div className="sticky z-20 top-[100px] left-0 w-full min-w-[420px] lg:max-w-[420px] space-y-1">
            <div className="w-full pb-[80px] bg-gray-200 rounded-md"></div>

            <div className="pt-6 pb-1">
              <div className="w-full max-w-[160px] pb-[14px] bg-gray-200 rounded-md"></div>
            </div>

            <div style={{ marginTop: 0 }} className="min-w-[400px] flex flex-col space-y-2 snap-y snap-mandatory overflow-auto">
              <div className="opacity-100"><div className="w-full pb-[45px] bg-gray-200 rounded-md"></div></div>
              <div className="opacity-90"><div className="w-full pb-[45px] bg-gray-200 rounded-md"></div></div>
              <div className="opacity-80"><div className="w-full pb-[45px] bg-gray-200 rounded-md"></div></div>
              <div className="opacity-70"><div className="w-full pb-[45px] bg-gray-200 rounded-md"></div></div>
              <div className="opacity-60"><div className="w-full pb-[45px] bg-gray-200 rounded-md"></div></div>
            </div>
          </div>
          
          <div className="w-full min-w-[425px] min-h-0 space-y-[2px]">
            <div className="w-full pb-[35px] bg-gray-200 rounded-md"></div>
            <div className="opacity-100"><div className="w-full pb-[158px] bg-gray-200 rounded-md"></div></div>
            <div className="opacity-80"><div className="w-full pb-[158px] bg-gray-200 rounded-md"></div></div>
            <div className="opacity-60"><div className="w-full pb-[158px] bg-gray-200 rounded-md"></div></div>
            <div className="opacity-40"><div className="w-full pb-[158px] bg-gray-200 rounded-md"></div></div>
            <div className="opacity-20"><div className="w-full pb-[158px] bg-gray-200 rounded-md"></div></div>
          </div>
        </div>
      : (project?.id)?
        <div className="w-full h-full flex items-start justify-start flex-wrap lg:flex-nowrap space-y-12 lg:space-y-0 lg:space-x-4 min-w-0">
          <div className="sticky z-20 top-[100px] left-0 w-full min-w-[420px] lg:max-w-[420px] space-y-1">
            <GlobalNotes projectId={match.params.id} />

            {(project?.widgets?.length > 1) && <>
              <p className="px-1 pt-5 pb-[2px] text-sm opacity-50">{translate("element_creator")}</p>
              <div
                id="widget-box"
                className={`min-w-[400px] flex flex-col space-y-2 snap-y snap-mandatory overflow-auto 
                  ${(scrollPosition > 75)? 'max-h-[calc(100vh-338px)]' : 'max-h-[calc(100vh-350px)]'}
                  ${(widgetBoxHasScroll)? 'pr-1' : ''}
                `}
                style={{ marginTop: 0 }}
              >

                {/* With overlays */}
                {(hasWidget("TextMessages")) && 
                  <MessageCreate projectId={project.id} projectStyles={project?.projectStyles} possiblePreview setShowingPreview={setShowingPreview}/>
                }

                {(hasWidget("AudioMessages")) && 
                  <AudioMessageCreate projectId={project.id} projectStyles={project?.projectStyles} possiblePreview setShowingPreview={setShowingPreview}/>
                }

                {(hasWidget("Cards")) && 
                  <CardCreate projectId={project.id} projectStyles={project?.projectStyles} categories={project.categories} possiblePreview setShowingPreview={setShowingPreview}/>
                }

                {(hasWidget("Chyrons")) &&
                  <ChyronCreate projectId={project.id} projectStyles={project?.projectStyles} possiblePreview setShowingPreview={setShowingPreview}/>
                }

                {(hasWidget("Polls")) &&
                  <PollCreate projectId={project.id} projectStyles={project?.projectStyles} possiblePreview setShowingPreview={setShowingPreview}/>
                }
              
                {(hasWidget("Subtitles")) &&
                  <SubtitlesCreate project={project} />
                }

                {/* Without overlays */}
                {(hasWidget("LiveChat")) && <>
                  <LiveChatYouTube project={project} />
                  <LiveChatTwitch project={project} />
                </>}

              </div>
            </>}
          </div>
          

          {(hasWidget("Overlay")) && 
            <div className="w-full min-w-[425px] min-h-0">
              <OverlayCreate
                project={project}
                role="admin"
              />
            </div>
          }
        </div>
      :
        <div className="relative h-[calc(100vh-310px)] px-10 lg:px-0 flex items-center justify-center overflow-hidden opacity-50 bg-gray-200 text-gray-500 rounded-md">
          <div className="flex items-center justify-center flex-wrap space-y-24 lg:flex-nowrap lg:space-y-0 lg:space-x-24 text-gray-600 rounded-md">
            <div className="scale-[10]">{sadFaceIcon}</div>
            <p className="w-full lg:w-auto lg:max-w-[350px] text-xl font-bold">{translate("could_not_find_projext")}</p>
          </div>
        </div>
      }
    </div>
  );
}








export default compose(
  firestoreConnect((props) => {
    const projectId = (props?.match?.params?.id)? props?.match?.params?.id : (props?.projectId)? props?.projectId : null;
    if (!projectId) return [];

    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [{ collection: "items", orderBy: ["savedAt", "asc"] }],
        storeAs: "items",
      },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [{ collection: "overlays" }],
        storeAs: "overlays",
      },
    ];
  }),

  connect(
    (state, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
      if (!projectId) { return {} }

      const overlays = state.firestore.ordered.overlays;
      const project = (state.firestore.data.projects)? state.firestore.data.projects[projectId] : null;
    
      return {
        project: {
          id: (project)? projectId : null,
          chatList: state.project.chatList,
          chatError: state.project.chatError,
          nextPageToken: state.project.nextPageToken,
          broadcastStatus: state.project.broadcastInfo,
          ...project,
          overlay: (overlays?.length)? overlays?.length : 0,
          overlays: (overlays?.length)? overlays?.map((el) => el?.snippet?.id) : null,
        },
        auth: state.firebase.auth,
        isAuthenticated: state.project.isAuthenticated,
      };
    }, 
    
    (dispatch, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
    
      return {
        broadcastList: () => dispatch(broadcastList()),
        broadcastInfo: (id) => dispatch(broadcastInfo(id)),
        saveItem: (type, item) => dispatch(saveItem(type, item, projectId)),
        deleteItem: (id) => dispatch(deleteItem(id, projectId)),
        setCategories: (value) => dispatch(setCategories(value, projectId)),
        removeOverlay: (id) => dispatch(removeOverlay(id, projectId)),
        setIsEmitted: (value, messageId) => dispatch(setIsEmitted(value, messageId, projectId)),
      };
    }
  )

)(RoomPanel);
