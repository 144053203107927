import React, { useState, useRef, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import MessagePanel from "./Overlays/Message/MessagePanel";
import AudioMessagePanel from "./Overlays/AudioMessage/AudioMessagePanel";
import ChyronPanel from "./Overlays/Chyron/ChyronPanel";
import CardPanel from "./Overlays/Card/CardPanel";
import PollPanel from "./Overlays/Poll/PollPanel";

import {

  toggleItemIsReady,
  setEditedMessage,
  setCategoria,
  setItemPosition,
  setIsEditing,
  setCategories,
  setIsEmitted,
  deleteItem

} from "../actions/projectActions";

import { 
  
  addOverlay, 
  removeOverlay,
  updateVoting,

} from "../actions/overlayActions";

import TextField from "./Inputs/TextField";
import { useIntl } from "react-intl";
import translate from "../i18n/translate";










function OverlayCreate(props) {



  const {

    project,
    role,

    setCategoria,
    setItemPosition,
    hiddeGroups,
    itemsTypeFilter,
    setCategories,

  } = props;

  const intl = useIntl();

  const deffaultCat = [{ id: 'null' }]


  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);
  const [initItemsLength, setInitItemsLength] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState('null')

  const [newCatName, setNewCatName] = useState('')
  const [addingCat, setAddingCat] = useState(false)

  /* Icon vars */
  const trashIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M5.896 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V5.125q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h3.375q0-.354.26-.615.261-.26.615-.26h3.187q.355 0 .615.26.26.261.26.615h3.375q.355 0 .615.26.26.261.26.615t-.26.615q-.26.26-.615.26V15.75q0 .729-.51 1.24-.511.51-1.24.51Zm1.542-4.375q0 .354.26.615.26.26.614.26.355 0 .615-.26.261-.261.261-.615V7.75q0-.354-.261-.615-.26-.26-.615-.26-.354 0-.614.26-.26.261-.26.615Zm3.333 0q0 .354.26.615.261.26.615.26t.614-.26q.261-.261.261-.615V7.75q0-.354-.261-.615-.26-.26-.614-.26t-.615.26q-.26.261-.26.615Z"/></svg>
  const downIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  const upIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h426q14.22 0 27.11 5Q682-806 693-795l102 102q11 11 16 23.89t5 27.11v426q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm264-108q45 0 76.5-31.5T588-360q0-45-31.5-76.5T480-468q-45 0-76.5 31.5T372-360q0 45 31.5 76.5T480-252ZM300-552h264q15.3 0 25.65-10.32Q600-572.65 600-587.91v-71.83q0-15.26-10.35-25.76Q579.3-696 564-696H300q-15.3 0-25.65 10.32Q264-675.35 264-660.09v71.83q0 15.26 10.35 25.76Q284.7-552 300-552Z"/></svg>
  const plusIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M431-431H269.78q-20.38 0-34.69-14.25-14.31-14.24-14.31-34.54 0-20.3 14.31-34.75Q249.4-529 269.78-529H431v-161.22q0-20.38 14.25-34.69 14.24-14.31 34.54-14.31 20.3 0 34.75 14.31Q529-710.6 529-690.22V-529h161.22q20.38 0 34.69 14.25 14.31 14.24 14.31 34.54 0 20.3-14.31 34.75Q710.6-431 690.22-431H529v161.22q0 20.38-14.25 34.69-14.24 14.31-34.54 14.31-20.3 0-34.75-14.31Q431-249.4 431-269.78V-431Z"/></svg>
  const backIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M324-192q-15 0-25.5-10.5T288-228q0-15 10.5-25.5T324-264h252q50 0 85-35t35-85q0-50-35-85t-85-35H330l67 68q11 11 11 25.5T397-385q-11 11-25 11t-25-11L217-515q-5-5-7.5-11.5T207-540q0-7 2.5-13.5T217-565l129-129q11-11 25.5-11t25.5 11q11 11 11.5 25T398-644l-68 68h246q80 0 136 56t56 136q0 80-56 136t-136 56H324Z"/></svg>









  useEffect(() => {
    const itemsTMP = (project?.items?.length)? project?.items?.filter((el) => !itemsTypeFilter || itemsTypeFilter?.includes(el?.type)) : null

    if (project) {
      if (!initItemsLength || initItemsLength >= itemsTMP?.length) {
        setInitItemsLength(itemsTMP?.length);
      }
      
      setItems((itemsTMP?.length)? itemsTMP?.sort((a, b) => a?.position - b?.position) : null);
    }

    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
        setInitItemsLength(project?.items?.length);
      }
    };
  }, [project, itemsTypeFilter]);









  


  function scrollToTopFunc() {
    try{ 
      window?.scrollTo({ top: 0, left: 0, behavior: 'smooth '})
      document.getElementById('elements-div')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    } catch(e) { console.error(e); }
  }


  function handleDrop(droppedItem) {
    if (!droppedItem.destination) return;
    var updatedList = [...items];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    for (var key in updatedList) {
      var element = updatedList[key];
      setItemPosition(key + 1, element.id)
    };
  };


  function addCategoryFunc() {
    var id = newCatName.toLocaleLowerCase().replaceAll(' ', '-');
    var newCatTMP = { id: id, txt: newCatName };
    var catsTMP = [...project.categories, newCatTMP];
    setCategories(catsTMP)

    setAddingCat(false);
    setNewCatName('');
  };








  function hasObjects(id) {
    const itemsTMP = items?.filter((item) => (!Object.hasOwn(item, 'category') && id === 'null') || (item.category === id) )?.filter((item) =>  (role === "presenter")? item.isReady : true);
    return (itemsTMP && itemsTMP?.length > 0)? true : false;
  };

  
  function removeCategory(id) {
    const itemsTMP = items?.map(element => {
      if (!Object.hasOwn(element, 'category') || element?.category === id) {        
        setCategoria('null', element.id);
        return {...element, category: 'null'};
      } else {
        return element;
      }
    });

    var categoriesTMP = project?.categories?.filter(function(element) { return element.id !== id });

    setItems(itemsTMP);
    setCategories(categoriesTMP);
  };




 

  return (
    <div id="elements-div" className={`w-full ${(role === "presenter")? 'space-y-4' : 'space-y-2'}`}>
        {(project?.categories?.length) &&
          project?.categories?.map((element, key) => {
            return <div
              className={`relative w-full rounded-md overflow-hidden 
                ${(!hiddeGroups) && `border-gray-300 dark:border-gray-600 border`}
              `}
            >

              {/* Group menu */}
              {(!hiddeGroups) &&
                <div className="pl-3 pr-1 py-1 bg-gray-200 dark:bg-gray-700 bg-opacity-75 flex justify-between items-center">
                  <p className="font-bold opacity-50" id={element.txt}>{(element?.id === 'null')? translate("new_elements") : element.txt}</p>

                  <div className="flex justify-between space-x-1 items-center">
                    {(role !== "subscriber" && element.id !== 'null') && 
                      <button className="btn--tool--red p-1" onClick={() => { if (window.confirm('Segur que vols eliminar aquesta categoria?')) { removeCategory(element.id) }}}> 
                        {trashIcon}
                      </button>
                    }


                    {/* Add groups */}
                    {/* {(element.id === 'null') && 
                      <div className="w-full flex items-center justify-end space-x-1">
                        {(addingCat)? <>
                            <div className="h-[25px] flex items-center">
                              <TextField
                                placeholder={intl?.formatMessage({ id: "write_the_name" })}
                                className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

                                value={(newCatName)? newCatName : ''}
                                onChange={(event) => setNewCatName((event.target.value)? event.target.value : null)}
                              />
                            </div>

                            <button onClick={() => addCategoryFunc()} className="btn--tool--blue pl-2 pr-3 py-1" disabled={!newCatName || newCatName.replaceAll(' ', '') === ''}> 
                              {saveIcon}
                              <p>{translate('save')}</p>
                            </button>

                            <button onClick={() => { setAddingCat(false); setNewCatName(''); }} className="btn--tool--gray px-2 py-1"> 
                              {backIcon}
                            </button>
                        </> : (role !== "emiter") && <>
                            <button onClick={() => setAddingCat(true)} className="btn--tool--gray pl-2 pr-3 py-1 opacity-70 hover:opacity-100"> 
                              {plusIcon}
                              <p>{translate('new_group')}</p>
                            </button>
                        </>}
                      </div>
                    } */}


                    {(hasObjects(element.id) && project?.categories?.length > 1) && 
                      <button
                        className={`p-1 ${(element.id === selectedCategory)? "btn--tool--blue" : "btn--tool--gray"}`}
                        onClick={() => {
                          setSelectedCategory((element.id !== selectedCategory)? element.id : null);
                          if (element.id !== selectedCategory) {scrollToTopFunc();}
                        }}
                      > 
                        {(element.id === selectedCategory)? upIcon : downIcon}
                      </button>
                    }
                  </div>
                </div>
              }



              {((element.id === selectedCategory) || hiddeGroups) && 
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable droppableId={"list-container-"+element.id}> 
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`${(role === 'presenter')? 'space-y-4' : ''}`}
                      >
                        {items?.filter((item) => ((item.category === element.id) || (!Object.hasOwn(item, 'category') && element.id === 'null'))).map((item, index) => {

                          if (/chatMessage/.test(item.type)) {
                            return (
                              <MessagePanel
                                key={item.id}
                                {...props}

                                index={index}
                                overlayItem={item}
                                setSelectedItem={setSelectedItem}
                                projectStyles={(project?.projectStyles)? project.projectStyles : null}
                              />
                            );
                          }

                          if (/audioMessage/.test(item.type)) {
                            return (
                              <AudioMessagePanel
                                key={item.id}
                                {...props}

                                index={index}
                                overlayItem={item}
                                setSelectedItem={setSelectedItem}
                                projectStyles={(project?.projectStyles)? project.projectStyles : null}
                              />
                            );
                          }

                          if ( /chyron/.test(item.type) ) {
                            return (
                              <ChyronPanel
                                key={item.id}
                                {...props}

                                index={index}
                                overlayItem={item}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                                projectStyles={project?.projectStyles? project.projectStyles : null}
                              />
                            );
                          }

                          if ( /poll/.test(item.type) ) {
                            return (
                              <PollPanel
                                key={item.id}
                                {...props}

                                overlayItem={item}
                                index={index}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                                projectStyles={project?.projectStyles? project.projectStyles : null}
                              />
                            );
                          }

                          if (/card/.test(item.type)) {
                            return (
                              <CardPanel
                                key={item.id}
                                {...props}
                                
                                index={index}
                                overlayItem={item}
                                setSelectedItem={setSelectedItem}
                              />
                            );
                          }

                        })}

                        {provided.placeholder}

                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              }
            </div>
          })
        }

        {(!items?.length) && 
          <p className="w-full px-2 py-1 opacity-50 bg-gray-300 bg-opacity-50 rounded-md">{translate("no_data_found")}</p>
        }
      </div>
  );
}





export default compose(
  firestoreConnect((props) => {
    const paramsId = props.match?.params.id ?? null;
    if (!paramsId) return [];

    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: paramsId,
        subcollections: [{ collection: "items", orderBy: ["savedAt", "asc"] }],
        storeAs: "items",
      },
      {
        collection: "projects",
        doc: paramsId,
        subcollections: [{ collection: "overlays" }],
        storeAs: "overlays",
      },
    ];
  }),

  connect(
    (state, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
      const { projects } = state.firestore.data;

      if ((!projectId && !ownProps?.project?.id) || !projects) { return {} }

      const project = (ownProps?.project?.id)? ownProps.project : projects[projectId];
      const { items, overlays } = state.firestore.ordered;

      return {
        project: {
          ...project,
          items: items,
          overlay: overlays?.length ?? 0,
          live_overlays: (overlays?.length)? overlays?.map((el) => el?.snippet?.id) : null,
        },
      } 
    },

    (dispatch, ownProps) => {
      const projectId = ownProps.project?.id ?? ownProps?.match?.params?.id;
      return {
        deleteItem: (id) => dispatch(deleteItem(id, projectId)),
        addOverlay: (message) => dispatch(addOverlay(message, projectId)),
        toggleItemIsReady: (id) => dispatch(toggleItemIsReady(id, projectId)),
        setEditedMessage: (value, messageId) => dispatch(setEditedMessage(value, messageId, projectId)),
        updateVoting: (value, pollId) => dispatch(updateVoting(value, pollId, projectId)),
        setCategoria: (value, messageId) => dispatch(setCategoria(value, messageId, projectId)),
        setItemPosition: (value, messageId) => dispatch(setItemPosition(value, messageId, projectId)),
        setIsEditing: (value, messageId) => dispatch(setIsEditing(value, messageId, projectId)),
        removeOverlay: (id) => dispatch(removeOverlay(id, projectId)),
        setIsEmitted: (value, messageId) => dispatch(setIsEmitted(value, messageId, projectId)),
        setCategories: (value) => dispatch(setCategories(value, projectId)),
      };
    }
  )

)(OverlayCreate);
