import { useState } from "react";

import ViewButtons from "../../ViewButtons";

import translate from "../../../../i18n/translate";





function CardPanelView({

  overlayItem,
  role,
  time,

  showPreview,
  setShowPreview,
  projectStyles,

  isEmiting,
  stopEmitingFunc,
  startEmitingFunc,
  setIsEmitted,

  editItemFunc,
  toggleItemIsReady,

}) {



  const [correctPicture, setCorrectPicture] = useState((overlayItem?.pictureURL)? true : false);

  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 316-265q-11 11-25 10.5T266-266q-11-11-11-25.5t11-25.5l163-163-164-164q-11-11-10.5-25.5T266-695q11-11 25.5-11t25.5 11l163 164 164-164q11-11 25.5-11t25.5 11q11 11 11 25.5T695-644L531-480l164 164q11 11 11 25t-11 25q-11 11-25.5 11T644-266L480-429Z"/></svg>
  const imageIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.5Q144-187 144-216v-528q0-29 21.15-50.5T216-816h528q29.7 0 50.85 21.5Q816-773 816-744v528q0 29-21.15 50.5T744-144H216Zm84-144h360.19Q671-288 676-298t-2-19L566-461q-5.25-8-14-8t-14 8l-94 125-58-77q-5.25-8-14-8t-14 8l-71.82 96.03Q279-308 284.25-298q5.25 10 15.75 10Z"/></svg>

  const displayTimeHour = (time) => new Intl.DateTimeFormat("es", { hour: "numeric", minute: "numeric" }).format(time);
  const emittedTime = overlayItem?.emittedAt?.toDate() ?? null;



  return <div className="flex-1 flex flex-col">

    <div className="flex items-start space-x-2">
      {(role !== "presenter") && (
        <div className="w-48 h-48 min-w-[192px] flex items-center justify-center border border-gray-300 dark:border-gray-600 rounded-md overflow-hidden">
          {(overlayItem?.showImageBox) && 
            ((overlayItem?.pictureURL && correctPicture)?
              <div className="w-48 h-48 min-w-[192px]">
                <img 
                  src={overlayItem?.pictureURL}
                  className={`w-48 h-48 min-w-[192px] object-cover ${(role === "presenter")? "filter grayscale opacity-60" : ""}` }
                  onError={() => setCorrectPicture(false)}
                />
              </div>
            :
              <div 
                className="w-48 h-48 min-w-[192px] text-3xl grid place-items-center font-regular bg-white text-gray-200"
              >
                <div className="scale-[3]">{imageIcon}</div>
              </div>
            )
          }
        </div>
      )}
      

      <div className={`w-full ${(role !== "presenter")? 'pb-2' : ''}`}>
        <div className="pb-1 w-full flex items-start justify-between space-x-2">
          <div className="pt-1 flex items-center space-x-2">
            <p className="font-semibold truncate">{overlayItem?.hiddenTitle}</p>

            {(overlayItem?.showPrimaryBox && role !== "presenter") && 
              <p className="hidden xl:block w-min py-1 px-2 bg-gray-400 bg-opacity-30 opacity-50 dark:bg-gray-600 rounded-md text-xs whitespace-nowrap">
                {translate("title")} && {translate("subtitle")}
              </p>
            }

            {(overlayItem?.showSecondaryBox && role !== "presenter") && 
              <p className="hidden xl:block w-min py-1 px-2 bg-gray-400 bg-opacity-30 opacity-50 dark:bg-gray-600 rounded-md text-xs whitespace-nowrap">
                {translate("inferior_texts")}
              </p>
            }
          </div>

          {(role !== "presenter") && 
            <div className="relative w-[140px] hidden lg:flex flex-col items-end">
              <p className="absolute top-0 right-[2px] whitespace-nowrap text-xs opacity-40">{(overlayItem?.lastUpdate)? translate("updated_at") : translate("created_at")} {displayTimeHour(time)}h</p>
              {(overlayItem?.isEmitted) && 
                <div className="absolute top-[14px] right-[2px] flex space-x-1 items-center whitespace-nowrap text-xs opacity-40">
                  <button onClick={() => setIsEmitted(false, overlayItem?.id)} className="hover:text-black duration-200">{cancelIcon}</button>
                  <p>{translate("emited_at")} {displayTimeHour(emittedTime)}</p>
                </div>
              }
            </div>
          }
        </div>


        <div className="space-y-2 text-gray-700">
          {(overlayItem?.showPrimaryBox) && 
            <div className="py-2 px-3 bg-gray-400 bg-opacity-25 text-gray-700 rounded-md">
              <div className="text-lg font-semibold" dangerouslySetInnerHTML={{ __html: (overlayItem?.title)? overlayItem?.title : '' }}></div>
              <div className="text-base pb-2" dangerouslySetInnerHTML={{ __html: (overlayItem?.subtitle)? overlayItem?.subtitle : '' }}></div>
              <div className="text-sm" dangerouslySetInnerHTML={{ __html: (overlayItem?.body)? overlayItem?.body : '' }}></div>
            </div>
          }

          {(overlayItem?.showSecondaryBox) &&
            <div className="py-2 px-3 bg-gray-400 bg-opacity-25 text-gray-700 rounded-md">
              <div className="text-sm" dangerouslySetInnerHTML={{ __html: (overlayItem?.secondaryBody)? overlayItem?.secondaryBody : '' }}></div>
            </div>
          }
        </div>



      </div>
    </div>



    {/* Buttons */}
    {(role !== "presenter") &&
      <ViewButtons
        overlayItem={overlayItem}
        role={role}
        type={translate("card")}

        showPreview={showPreview}
        setShowPreview={setShowPreview}
      
        isEmiting={isEmiting}
        stopEmitingFunc={stopEmitingFunc}
        startEmitingFunc={startEmitingFunc}
      
        editItemFunc={editItemFunc}
        toggleItemIsReady={toggleItemIsReady}
      />
    }
  </div>
}

export default CardPanelView;
