import { LANGUAGES } from '../languages'

const es = {
  [LANGUAGES.es]: {
    
    /* Others */
    "hello": "Hola buenos días. ¿cómo va todo?",
    'catala': "Catalan",
    'castella': "Español",
    'titol_configuracio': "Configuración",

    /* Titles */
    'room': "Sala",
    'rooms': "Salas",
    'global_panel': "Panel global",
    'text_message': "Mensaje de texto",
    'text_messages': "Mensajes de texto",
    'audio_message': "Mensaje de audio",
    'audio_messages': "Mensajes de audio",
    'card': "Tarjeta",
    'cards': "Tarjetas",
    'subtitler': "Subtitulado",
    'subtitles': "Subtítulos",
    'poll': "Enquesta",
    'polls': "Enquestas",
    'obs_scene_links': "Enlaces de las escenas de OBS",
    'style_configurator': "Editor de estilos",
    'emiter_panel': "Panel de emisión",
    'subtitler_panel': "Panel de subtitulación",
    'text_message_panel': "Panel de inserción de mensajes de texto",
    'audio_message_panel': "Panel de inserción de mensajes de audio",
    'presenter_panel': "Panel del presentador",
    'presenter_notifications_panel': "Panel de notificaciones del presentador",
    'widgets_and_panels': "Paneles / Widgets de la sala",
    'text_messages_moderator': "Moderador de mensajes de texto",
    'audio_messages_moderator': "Moderador de mensajes de audio",
    'subtitles_plus_video': "Subtítols + Vídeo",

    /* Labels */
    'room_name': "Nombre de la sala",
    'write_room_name': "Escribe un nombre para esta sala",
    'name': "Nombre",
    'write_the_name': "Escribe el nombre",
    'busines_name': "Nombre de la empresa",
    'write_the_busines_name': "Escribe el nombre de la empresa",
    'font_size': "Tamaño del texto",
    'small_text': "Texto pequeño",
    'normal_text': "Texto normal",
    'big_text': "Texot grande",
    'text_of_the_message': "Texto del mensaje",
    'write_text_of_the_message': "Escribe el texto del mensaje",
    'created_at': "Creado a las",
    'updated_at': "Actualizado a las",
    'category': "Categoría",
    'title': "Título",
    'write_the_title': "Escribe un título",
    'intern_title': "Título interno",
    'write_the_intern_title': "Escribe un título interno",
    'subtitle': "Subtítulo",
    'inferior_texts': "Textos inferiores",
    'write_youtube_id': "Escribe la ID de Youtube",
    'subtitles_public_url': "URL pública de los subtítulos",
    'write_url': "Escribe la URL",
    'option': "Opción",
    'write_option': "Escribe la opción",
    'search_bar': "Barra de busqueda",
    'vote': "Voto",
    'votes': "Votos",
    'presenters': "Presentadores",
    'emisor': "Emisores",
    'personalization': "Personalicación",
    'live_chats': "Chats en directo de YouTube / Twitch",
    'elements_list': "Listado de elementos",
    'presenter_notes': "Notas del presentador",
    'element_creator': "Creador de elementos",
    'welcome_to_sstrm': "Bienvenido a sstrm",
    'login_with_google': "Inicia sessión con Google",
    'loading': "Cargando...",
    'project_not_found': "Proyecto no encontrado",

    /* Buttons */
    'logout': "Cerrar sesión",
    'configuration': "Configuración",
    'dark_mode': "Modo oscuro",
    'save': "Guardar",
    'saving': "Guardando...",
    'new_room': "Nueva sala",
    'room_list': "Listado de salas",
    'add_new_room': "Crear una nueva sala",
    'go_back': "Volver",
    'cancel': "Cancelar",
    'show_portrait': "Mostrar retrato",
    'image': "Imagen",
    'remove_image': "Borrar imagen",
    'upload_image': "Subir imagen",
    'show_preview': "Vista previa",
    'remove_audio': "Borrar audio",
    'upload_audio': "Subir audio",
    'emited_at': "Emitido a las",
    'not_emiting': "Ningún elemento emitiendo",
    'emiting_elements': "Emitiendo elementos",
    'stop_emiting_all': "Detener elementos en transmisión",
    'stop_emit_subtitles': "Detener emisión de subtítulos",
    'emit_subtitles': "Emitir subtítulos",
    'remove_option': "Eliminar opción",
    'remove': "Eliminar",
    'add_option': "Añadir opción",
    'reset_votes': "Reiniciar votos",
    'poll_link': "Enlace enquesta",
    'show_qr': "Mostrar QR",
    'hide_qr': "Esconder QR",
    'open_poll': "Abrir enquesta",
    'close_poll': "Cerrar enquesta",
    'see_votes': "Ver votos",
    'link_account_to_start': "Enlaza la cuenta para empezar",
    'select_active_live': "Selecciona una emisión",
    'refresh_active_live': "Refrescar emisiones activas",
    'live_chat': "Chat en directo",
    'obs_links': "Enlaces de las escenas (OBS)",
    'new_group': "Nuevo grupo",
    'settings': "Configuración",
    'language': "Idioma",
    'awaiting': "A la espera",
    'emiting': "Emitiendo",
    'confirmed': "Confirmado",
    'confirm': "Confirmar",
    'edit': "Editar",
    'exit': "Salir",
    'go_back_to_start': "Volver al inicio",
    'new_elements': "Nuevos elementos",

    /* Long */
    'search_text': "Busca tus proyectos por su nombre.",
    'search_voters': "Busca los datos de los votantes",
    'sure_delete_room': "¿Seguro que quiere eliminar esta sala, junto con toda la información que contiene?",
    'sure_delete_element': "¿Seguro que quiere eliminar este elemento de forma definitiva?",
    'sure_reset_votes': "¿Seguro que quiere eliminar los votos de esta enquesta de forma definitiva?",
    'sure_edit_poll': "¿Seguro que quiere editar esta enquesta? Su edición puede significar una perdida de los votos introducidos.",
    'no_data_found': "No se han encontrado datos",
    'no_active_lives': "No se han encontrado emisiones activas",
    'global_note_removed': "Esta nota ha sido eliminada por el presentador, edítala o haz una nueva para que la visualice.",
    'could_not_find_projext': "No hemos encontrado nincgún proyecto con esta ID, asegurese de que la sala es correcta o contacte con el desarrollador",

  }
}

export default es