import { useState, useEffect } from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import OverlayCreate from '../../components/OverlayCreate'

import { broadcastList, broadcastInfo } from "../../actions/youtubeActions";
import { saveItem, deleteItem, setCategories, setIsEmitted } from "../../actions/projectActions";
import { addOverlay, removeOverlay } from "../../actions/overlayActions";
import translate from '../../i18n/translate';
import { useIntl } from 'react-intl';






function EmitModerator(props) {




  const {

    project,

  } = props

  const intl = useIntl();



  const [scrollPosition, setScrollPosition] = useState(0);

  const emitingOffIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"/></svg>
  const emitingOnIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"/></svg>





  useEffect(() => {
    document.title = `${intl?.formatMessage({ id: "emiter_panel" })} - SSTRM`

    window.addEventListener('scroll', windowScrollListenerFunc, { passive: true });

    return () => {
      window.removeEventListener('scroll', windowScrollListenerFunc);
    };
  }, []);



  
  

  function windowScrollListenerFunc() {
    setScrollPosition(window.pageYOffset)
  }


  function submitOverlay(messageId) {
    if (project?.overlay) {
      removeOverlay();

      if (messageId) setIsEmitted(true, messageId);
      if (!messageId && project?.overlays?.length) project?.overlays?.map((el) => setIsEmitted(true, el));
    }
  };





  return (
    <div className="w-full min-w-[455px] flex justify-center px-4 lg:px-8 py-76 lg:py-[112px]">
      <div className="w-full max-w-[1000px]">
        <div className={`sticky top-0 left-0 z-40 flex items-end justify-between space-x-2 py-3 bg-white dark:bg-gray-800 border-b ${(scrollPosition > 100)? 'border-gray-300' : 'border-transparent'}`}>
          <div className="flex items-end space-x-3 font-black">
            <h1 className="text-3xl md:text-4xl">{translate("emiter_panel")}</h1>
            <p className="truncate text-xl opacity-40 hidden sm:block"> / {project?.title}</p>
          </div>

          <button
            className={`px-4 py-3 flex items-center space-x-2 justify-center font-semibold text-sm hover:bg-red-700 hover:shadow-lg text-white rounded-md duration-200
              ${(project?.overlay)? 'bg-red-600' : 'bg-red-900 bg-opacity-70'}
            `}
            onClick={() => submitOverlay()}
            disabled={!project?.overlay}
          >
            {(project?.overlay)? emitingOnIcon : emitingOffIcon}
            <p className="hidden lg:block">{(project?.overlay)? translate("stop_emiting_all") : translate("not_emiting")}</p>
          </button>
        </div>


        <OverlayCreate 
          project={project}
          role="emiter"
        />
      </div>
    </div>
  )
}








export default compose(
  firestoreConnect((props) => {
    const projectId = (props?.match?.params?.id)? props?.match?.params?.id : (props?.projectId)? props?.projectId : null;
    if (!projectId) return [];

    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [{ collection: "items", orderBy: ["savedAt", "desc"] }],
        storeAs: "items",
      },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [{ collection: "overlays" }],
        storeAs: "overlays",
      },
    ];
  }),

  connect(
    (state, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
      if (!projectId) { return {} }

      const overlays = state.firestore.ordered.overlays;
      const project = (state.firestore.data.projects)? state.firestore.data.projects[projectId] : null;
    
      return {
        project: {
          id: projectId,
          chatList: state.project.chatList,
          chatError: state.project.chatError,
          nextPageToken: state.project.nextPageToken,
          broadcastStatus: state.project.broadcastInfo,
          ...project,
          overlay: (overlays?.length)? overlays?.length : 0,
          overlays: (overlays?.length)? overlays?.map((el) => el?.snippet?.id) : null,
        },
        auth: state.firebase.auth,
        isAuthenticated: state.project.isAuthenticated,
      };
    }, 
    
    (dispatch, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
    
      return {
        broadcastList: () => dispatch(broadcastList()),
        broadcastInfo: (id) => dispatch(broadcastInfo(id)),
        saveItem: (type, item) => dispatch(saveItem(type, item, projectId)),
        deleteItem: (id) => dispatch(deleteItem(id, projectId)),
        setCategories: (value) => dispatch(setCategories(value, projectId)),
        removeOverlay: (id) => dispatch(removeOverlay(id, projectId)),
        setIsEmitted: (value, messageId) => dispatch(setIsEmitted(value, messageId, projectId)),
      };
    }
  )

)(EmitModerator);
