import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";

import { connect } from "react-redux";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

import translate from "../i18n/translate";

import Spinner from "../components/Spinner";
import SearchInput from "../components/Inputs/SearchInput";
import LinkButton from "../components/Inputs/LinkButton";

import { deleteProject } from "./../actions/projectActions";
import { useIntl } from "react-intl";
import MenuUserProfile from "../components/MenuUserProfile";








function Dashboard({ projects, auth, deleteProject }) {




  const intl = useIntl();

  const [search, setSearch] = useState(null);
  const [filtredProjects, setFiltredProjects] = useState(null);

  const plusIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M431-431H269.78q-20.38 0-34.69-14.25-14.31-14.24-14.31-34.54 0-20.3 14.31-34.75Q249.4-529 269.78-529H431v-161.22q0-20.38 14.25-34.69 14.24-14.31 34.54-14.31 20.3 0 34.75 14.31Q529-710.6 529-690.22V-529h161.22q20.38 0 34.69 14.25 14.31 14.24 14.31 34.54 0 20.3-14.31 34.75Q710.6-431 690.22-431H529v161.22q0 20.38-14.25 34.69-14.24 14.31-34.54 14.31-20.3 0-34.75-14.31Q431-249.4 431-269.78V-431Z"/></svg>
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" /></svg>
  const emitIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" /></svg>





  
  useEffect(() => {
    document.title = `${intl?.formatMessage({ id: "room_list" })} - SSTRM`
  }, []);


  useEffect(() => {   
    var filtredProjectsTMP = (projects?.length)? projects : null;

    if (filtredProjectsTMP?.length && search) {
      filtredProjectsTMP = filtredProjectsTMP?.filter((el) => el?.title?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase()))
    }

    setFiltredProjects(filtredProjectsTMP)
  }, [projects, search])




  function deleteProjectFunc(id) {
    const confirm = window.confirm(intl?.formatMessage({ id: "sure_delete_room" }));
    if (confirm) { deleteProject(id); }
  };






  if (!auth.uid) return <Redirect to="/signin" />;

  return (
    <div className="w-full h-full flex justify-center">
      <div className="w-full max-w-[1200px] space-y-4 px-4 pt-14 md:px-8 md:pt-28">

        <div className="flex items-center justify-between space-x-4">
          <h1 className="text-3xl md:text-4xl font-black">{translate("rooms")}</h1>

          <div className="flex space-x-3">
            <LinkButton to="/room/new" color="blue" icon>
              {plusIcon}
              <span>{translate("new_room")}</span>
            </LinkButton>
            
            <MenuUserProfile arrow={true} />
          </div>
        </div>


        <SearchInput
          id="dashboard-search"
          name="dashboard-search"
          label={intl?.formatMessage({ id: "search_text" })}

          value={search}
          setValue={setSearch}
        />


        <div className="bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md max-h-[calc(100vh-180px)] md:max-h-[calc(100vh-265px)] overflow-hidden">
          <div className="max-h-[calc(100vh-180px)] md:max-h-[calc(100vh-265px)] overflow-auto">
            <ul>
              {(filtredProjects?.length)?
                filtredProjects.map((project, key) => {
                  return (
                    <li
                      key={project.id}
                      className={`flex items-center justify-between hover:bg-blue-200 hover:bg-opacity-40 active:bg-opacity-75 border-gray-300 dark:border-gray-600 duration-200 cursor-pointer
                        ${(key !== filtredProjects.length - 1)? "border-b" : ""}
                      `}
                    >
                      <Link to={`/room-panel/${project.id}`} className="px-2 py-2 w-full flex items-center space-x-2">
                        {emitIcon}

                        <span>
                          <span className="font-semibold">{project.title}</span>
                          
                          {project?.widgets?.map((widget, key) =>
                            <span key={widget + key} className="text-xs opacity-50 ml-1.5">{widget}{(project.widgets.length !== key + 1)? "," : ""}</span>
                          )}
                        </span>
                      </Link>
                      

                      <button onClick={() => deleteProjectFunc(project.id)} className="p-3 hover:text-red-500 active:scale-90 duration-200">
                        {removeIcon}
                      </button>
                    </li>
                  );
                })
              :
                <Spinner className="mx-3 my-2" />
              }
            </ul>
          </div>
        </div>


      </div>
    </div>
  );
}







const mapStateToProps = (state) => {
  return {
    projects: state.firestore.ordered.projects,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteProject: (id) => dispatch(deleteProject(id)),
  };
};




export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.auth.uid) return [];
    return [
      {
        collection: "projects",
        orderBy: ["createdAt", "desc"],
      },
    ];
  })
)(Dashboard);




